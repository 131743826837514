import store from '@/store'

export function getTransferHeaderFields($t) {
  return [
    {
      name: 'fromStoreName',
      is_visible: true,
      get short_title() {
        return $t('Transfer.senderStore')
      },
      get title() {
        return $t('Transfer.senderStore')
      }
    },
    {
      name: 'toStoreName',
      is_visible: true,
      get short_title() {
        return $t('Transfer.buyerStore')
      },
      get title() {
        return $t('Transfer.buyerStore')
      }
    },
    {
      name: 'statusType',
      type: 'status',
      is_visible: true,
      get short_title() {
        return $t('Transfer.statusType')
      },
      get title() {
        return $t('Transfer.statusType')
      },
      getStatusText: function(row) {
        switch (row.statusType) {
          case 1:
            return $t('Transfer.header_statusType_WaitingForFinalize')
          case 2:
            return $t('Transfer.header_statusType_TransferSaved')
          case 3:
            return $t('Transfer.header_statusType_WaitingForApproval')
          case 4:
            return $t('Transfer.header_statusType_Rejected')
          default:
            return '-'
        }
      }
    },
    {
      type: 'date',
      name: 'transferDate',
      is_visible: true,
      get short_title() {
        return $t('Transfer.Transfer_Date')
      },
      get title() {
        return $t('Transfer.Transfer_Date')
      }
    },
    {
      name: 'code',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Transfer.Transfer_No')
      },
      get title() {
        return $t('Transfer.Transfer_No')
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.total_amount')
      },
      get title() {
        return $t('Global.total_amount')
      }
    },
    {
      name: 'approvalStatus',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalStatus')
      },
      get title() {
        return $t('Global.approvalStatus')
      }
    },
    {
      name: 'maxApprovalLevel',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalLevel')
      },
      get title() {
        return $t('Global.approvalLevel')
      }
    },
    {
      name: 'rejectionReason',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },
    {
      name: 'info',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },
  ]
}
export function getTransferDetailFields($t, $getConst, isDetailDocument = false) {
  return [
    { type: 'fixed',
      name: 'name',
      is_visible: true,
      withDescription: true,
      getTypeText: function(type) {
        return {
          text: type === 2 ? $t('Dictionary.Recipe') : '',
          class: 'p-5 bgc-seashell c-success border-gray border-radius-4 font-size-micro-small font-weight-600 m-left-10',
          id: 'icon-recipe'
        }
      },
      get short_title() {
        return $t('Transfer.detail_field_name')
      },
      get title() {
        return $t('Transfer.detail_field_name')
      }
    },
    {
      name: 'fromStoreStockOnHandQuantity',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_TableColumn_fromStoreStockOnHandQuantity')
      },
      get title() {
        return $t('Transfer.detail_TableColumn_fromStoreStockOnHandQuantity')
      }
    },
    {
      name: 'toStoreStockOnHandQuantity',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_TableColumn_toStoreStockOnHandQuantity')
      },
      get title() {
        return $t('Transfer.detail_TableColumn_toStoreStockOnHandQuantity')
      }
    },
    {
      name: 'requestQuantity',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_TableColumn_requestQuantity')
      },
      get title() {
        return $t('Transfer.detail_TableColumn_requestQuantity')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      editable: true,
      disabledStatus: row => row.approvalStatus > 0,
      validationRules: item => {
        return {
          decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value: 0, multiplyTwoField: item.unitPrice, max: $getConst('MAX_DIGITS_FOR_QUANTITY')
        }
      },
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'productionQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_Popup_FormField_ProductionQuantity')
      },
      get title() {
        return $t('Transfer.detail_Popup_FormField_ProductionQuantity')
      }
    },
    {
      name: 'unitName',
      type: 'portal',
      is_visible: isDetailDocument,
      get short_title() {
        return $t('Dictionary.Unit')
      },
      get title() {
        return $t('Dictionary.Unit')
      }
    },
    { type: 'select',
      editable: true,
      validationRules: 'required',
      filterKey: 'baseUnitId',
      dataField: 'unit',
      disabled: (row) => row.type === 2 || row.approvalStatus > 0,
      selectList: {
        value: 'id',
        label: 'name',
        options: function(baseUnitId) {
          const unitList = store.getters['Units/UnitList']
          return unitList.filter(item => item.baseUnitId === baseUnitId && !item.isRecipeUnit)
        }
      },
      name: 'unitId',
      is_visible: !isDetailDocument,
      get short_title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      }
    },
    {
      type: 'currency',
      name: 'unitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_NetUnitPrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_NetUnitPrice')
      }
    },
    {
      name: 'baseQuantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Despatches.detail_TableColumn_BaseQuantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BaseQuantity')
      }
    },
    {
      name: 'baseUnitCode',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_BaseUnit')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BaseUnit')
      }
    },
    { type: 'currency',
      name: 'baseUnitPrice',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_BasePrice')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_BasePrice')
      }
    },
    { type: 'currency',
      name: 'totalAmount',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_TableColumn_Price')
      },
      get title() {
        return $t('Transfer.detail_TableColumn_Price')
      }
    },

    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
