import Transfer from '../index'
import TransferDocumentDetail from '../transfer-detail-document'
import TransferForm from '../popup/form'
import TransferDetailForm from '../popup/transfer-detail-form'
import RemoveTransfer from '../popup/remove-transfer-alert'
import RemoveTransferDetail from '../popup/remove-transfer-detail-alert'

export default [
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
    meta: {
      main_menu: true,
      slug: 'transfer',
      page: 'transfer',
      category: 'Stock',
      permissionKey: 'TransferMenu'
    },
    children: [
      {
        path: 'new',
        name: 'NewTransfer',
        component: TransferForm
      },
      {
        path: ':id/edit',
        name: 'EditTransfer',
        props: (route) => ({
          status: 'edit',
          transferId: route.params.id
        }),
        component: TransferForm
      },
      {
        path: ':id/delete',
        name: 'RemoveTransfer',
        component: RemoveTransfer
      },
      {
        path: ':id/newDetail',
        name: 'NewTransferDetail',
        component: TransferDetailForm
      },
      {
        path: ':id/:detailId/detail-edit',
        name: 'EditTransferDetail',
        props: {
          status: 'edit'
        },
        component: TransferDetailForm
      },
      {
        path: ':id/:detailId/detail-remove',
        name: 'RemoveTransferDetail',
        component: RemoveTransferDetail
      }
    ]
  },
  {
    path: '/transfer/detail/:id/:documentNumber',
    name: 'TransferDocumentDetail',
    component: TransferDocumentDetail,
    meta: {
      tabPermissionKey: 'TransferMenu',
      page: 'transfer'
    }
  }
]
