var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.formTitle))]),
      _c("template", { slot: "detail" }, [
        _c(
          "div",
          {
            staticClass: "Popup-despatch-info row",
            attrs: { id: "description-transfer-detail" },
          },
          [
            _c("div", { attrs: { id: "description-transfer-no-title" } }, [
              _vm._v(_vm._s(_vm.$t("Transfer.Transfer_No")) + " :"),
            ]),
            _c(
              "div",
              {
                staticClass: "ff-mono",
                attrs: { id: "description-transfer-no" },
              },
              [_vm._v(_vm._s(_vm.transferHeaderInfo.code))]
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "selectType" },
                [
                  _c("SelectedButtons", {
                    attrs: {
                      data: [
                        { text: _vm.$t("Dictionary.StockItem"), value: 1 },
                        { text: _vm.$t("Dictionary.Recipe"), value: 2 },
                      ],
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isLoadingForm ? _c("Loading") : _vm._e(),
          !_vm.isLoadingForm
            ? _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-transfer-detail" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitFormTransfer.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isFormTypeStock
                    ? _c("div", [
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockItemName"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: _vm.isFormTypeStock,
                                      verify_inventory: true,
                                    },
                                    expression:
                                      "{  required: isFormTypeStock, verify_inventory: true}",
                                  },
                                ],
                                ref: "firstInput",
                                attrs: {
                                  "component-name":
                                    "transfer-detail-stock-item",
                                  name: "stockItem",
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_StockItemName"
                                  ),
                                  inputClass: {
                                    "is-danger": _vm.veeErrors.has("stockItem"),
                                    verify_inventory: true,
                                  },
                                  "on-search": _vm.getOptions,
                                  options: _vm.selectOptions,
                                  disabled: _vm.isEdit,
                                  label: "name",
                                  placeholder: _vm.$t(
                                    "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                                  ),
                                  directSelectOption: _vm.directSelectOption,
                                },
                                on: { resetOptions: _vm.resetSearch },
                                model: {
                                  value: _vm.form.stockItem,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "stockItem", $$v)
                                  },
                                  expression: "form.stockItem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("stockItem"),
                                  expression: "veeErrors.has('stockItem')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                              attrs: {
                                id: "validate-message-transfer-detail-stock-item",
                              },
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                          ),
                          _vm.form.stockItem
                            ? _c("div", { staticClass: "Form-item-info" }, [
                                _c("div", [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Transfer.stockAmount"))
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedStock.baseUnitQuantity
                                        ) +
                                          " " +
                                          _vm._s(_vm.selectedStock.baseUnitName)
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "Form-item required row-field" },
                          [
                            _c("div", { staticClass: "item" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Despatches.detail_Popup_FormField_Quantity"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("customNumberInput", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: _vm.allowNegativeStock
                                          ? {
                                              required: _vm.isFormTypeStock,
                                              decimal: _vm.$getConst(
                                                "DECIMAL_FOR_QUANTITY"
                                              ),
                                              greater_than: 0,
                                              max: _vm.$getConst(
                                                "MAX_DIGITS_FOR_QUANTITY"
                                              ),
                                            }
                                          : {
                                              required: _vm.isFormTypeStock,
                                              decimal: _vm.$getConst(
                                                "DECIMAL_FOR_QUANTITY"
                                              ),
                                              greater_than: -1,
                                              max_value: !_vm.canProduction
                                                ? _vm.selectedStock
                                                    .baseUnitQuantity
                                                : 999999999999999,
                                            },
                                        expression:
                                          "allowNegativeStock ? { required: isFormTypeStock, decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')} : { required: isFormTypeStock, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:-1, max_value: !canProduction ? selectedStock.baseUnitQuantity : 999999999999999}",
                                      },
                                    ],
                                    class: {
                                      "is-danger":
                                        _vm.veeErrors.has("quantity"),
                                    },
                                    attrs: {
                                      id: "input-transfer-detail-form-quantity",
                                      name: "quantity",
                                      "data-vv-as": _vm.$t(
                                        "Despatches.detail_Popup_FormField_Quantity"
                                      ),
                                      disabled: !_vm.form.stockItem,
                                    },
                                    on: { input: _vm.onChangeQuantity },
                                    model: {
                                      value: _vm.form.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "quantity", $$v)
                                      },
                                      expression: "form.quantity",
                                    },
                                  }),
                                  _c("showValidateError", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.veeErrors.has("quantity"),
                                        expression: "veeErrors.has('quantity')",
                                      },
                                    ],
                                    attrs: {
                                      errorName:
                                        _vm.veeErrors.first("quantity"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "item" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Despatches.detail_Popup_FormField_BaseUnit"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("CustomSelect", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|is_not:-1",
                                        expression: "'required|is_not:-1'",
                                      },
                                    ],
                                    attrs: {
                                      id: "select-transfer-popup-select-unit",
                                      name: "unit",
                                      optionData: _vm.filteredUnitList,
                                      isSearchActive: true,
                                      "data-vv-as": _vm.$t("Dictionary.Unit"),
                                      error: _vm.veeErrors.has("unit"),
                                      isDefaultTextActive: true,
                                      defaultTitle: _vm.$t(
                                        "Transfer.selectUnit"
                                      ),
                                      optionTitle: "name",
                                      selectedEmitValue: "id",
                                      isDisabled: !_vm.form.stockItem,
                                    },
                                    on: { inputSelected: _vm.onStockChange },
                                    model: {
                                      value: _vm.form.unitId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "unitId", $$v)
                                      },
                                      expression: "form.unitId",
                                    },
                                  }),
                                  _c("showValidateError", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.veeErrors.has("unit"),
                                        expression: "veeErrors.has('unit')",
                                      },
                                    ],
                                    attrs: {
                                      errorName: _vm.veeErrors.first("unit"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        (
                          _vm.isEdit
                            ? _vm.stockItemType === 2 && _vm.canProduction
                            : _vm.canProduction &&
                              _vm.form.stockItem?.type === 2
                        )
                          ? _c("div", { staticClass: "Form-item row-field" }, [
                              _c("div", { staticClass: "item" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "Form-item-label",
                                    attrs: {
                                      id: `transfer-detail-popup-form-label-productionquantity`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Transfer.detail_Popup_FormField_ProductionQuantity"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("customNumberInput", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { min_value: 0 },
                                          expression: "{min_value:0}",
                                        },
                                      ],
                                      attrs: {
                                        id: "transfer-detail-productionQuantity",
                                        name: "productionQuantity",
                                        error:
                                          _vm.veeErrors.has(
                                            "productionQuantity"
                                          ),
                                        "data-vv-as": _vm.$t(
                                          "Transfer.detail_Popup_FormField_ProductionQuantity"
                                        ),
                                        disabled: !_vm.form.quantity,
                                      },
                                      model: {
                                        value: _vm.form.productionQuantity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "productionQuantity",
                                            $$v
                                          )
                                        },
                                        expression: "form.productionQuantity",
                                      },
                                    }),
                                    _c("showValidateError", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.veeErrors.has(
                                              "productionQuantity"
                                            ),
                                          expression:
                                            "veeErrors.has('productionQuantity')",
                                        },
                                      ],
                                      attrs: {
                                        errorName:
                                          _vm.veeErrors.first(
                                            "productionQuantity"
                                          ),
                                      },
                                    }),
                                    _vm.form.stockItem
                                      ? _c(
                                          "div",
                                          { staticClass: "Form-item-info" },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Transfer.baseUnitName"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "value" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedStock
                                                          .baseUnitName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "item" }, [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Transfer.ProductionUnit"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "control form-item-select" },
                                  [
                                    _c("CustomSelect", {
                                      attrs: {
                                        name: "productionUnit",
                                        optionData: _vm.filteredUnitList,
                                        "data-vv-as": _vm.$t(
                                          "Transfer.ProductionUnit"
                                        ),
                                        isSearchActive: true,
                                        error:
                                          _vm.veeErrors.has("productionUnit"),
                                        isDefaultTextActive: false,
                                        defaultTitle: _vm.$t(
                                          "Transfer.selectProductionUnit"
                                        ),
                                        optionTitle: "name",
                                        selectedEmitValue: "id",
                                        id: "transfer-select-productionUnit",
                                        isDisabled:
                                          !_vm.form.productionQuantity &&
                                          _vm.form.productionQuantity !== 0,
                                      },
                                      model: {
                                        value: _vm.form.productionUnitId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "productionUnitId",
                                            $$v
                                          )
                                        },
                                        expression: "form.productionUnitId",
                                      },
                                    }),
                                    _c("showValidateError", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.veeErrors.has("productionUnit"),
                                          expression:
                                            "veeErrors.has('productionUnit')",
                                        },
                                      ],
                                      attrs: {
                                        errorName:
                                          _vm.veeErrors.first("productionUnit"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "Form-item" }, [
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Despatches.detail_Popup_FormField_Price"
                                    )
                                  )
                                ),
                              ]),
                              _c("customNumberInput", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { min_value: 0 },
                                    expression: "{min_value: 0}",
                                  },
                                ],
                                attrs: {
                                  id: "transfer-detail-price",
                                  name: "price",
                                  error: _vm.veeErrors.has("price"),
                                  disabled:
                                    _vm.selectedStock.listPriceType !== 2 &&
                                    _vm.selectedStock.listPriceType !== 3,
                                  placeholder: _vm.$t(
                                    "Despatches.detail_Popup_FormField_Price"
                                  ),
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_Price"
                                  ),
                                },
                                on: { input: _vm.changePrice },
                                model: {
                                  value: _vm.form.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "price", $$v)
                                  },
                                  expression: "form.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : [
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("Dictionary.Recipe"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: !_vm.isFormTypeStock },
                                    expression:
                                      "{ required: !isFormTypeStock }",
                                  },
                                ],
                                ref: "firstInputRecipe",
                                attrs: {
                                  "component-name": "transfer-detail-recipe",
                                  name: "recipe",
                                  "data-vv-as": _vm.$t("Dictionary.Recipe"),
                                  disabled: _vm.isEdit,
                                  inputClass: {
                                    "is-danger": _vm.veeErrors.has("recipe"),
                                  },
                                  "on-search": _vm.getRecipeOptions,
                                  options: _vm.selectedOptionsRecipe,
                                  label: "name",
                                  placeholder: _vm.$t(
                                    "ListsWaste.detail_Popup_FormField_RecipeName_Placeholder"
                                  ),
                                  directSelectOption:
                                    _vm.recipeDirectSelectOption,
                                },
                                on: { afterSelected: _vm.setRecipe },
                                model: {
                                  value: _vm.form.recipe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "recipe", $$v)
                                  },
                                  expression: "form.recipe",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("recipe"),
                                  expression: "veeErrors.has('recipe')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                              attrs: {
                                id: "validate-message-transfer-detail-recipe",
                              },
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("recipe")))]
                          ),
                        ]),
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_Quantity"
                                )
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.form.quantity,
                                  expression: "form.quantity",
                                  modifiers: { number: true },
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    required: !_vm.isFormTypeStock,
                                    decimal: _vm.$getConst(
                                      "DECIMAL_FOR_QUANTITY"
                                    ),
                                    greater_than: 0,
                                  },
                                  expression:
                                    "{ required: !isFormTypeStock, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0 }",
                                },
                              ],
                              staticClass: "txt",
                              class: {
                                "is-danger":
                                  _vm.veeErrors.has("Recipequantity"),
                              },
                              attrs: {
                                name: "Recipequantity",
                                id: "input-transfer-detail-form-recipe-quantity",
                                autocomplete: "off",
                                "data-vv-as": _vm.$t(
                                  "Despatches.detail_Popup_FormField_Quantity"
                                ),
                                type: "number",
                                step: "any",
                              },
                              domProps: { value: _vm.form.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "quantity",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("Recipequantity"),
                                  expression: "veeErrors.has('Recipequantity')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                              attrs: {
                                id: "validate-message-transfer-detail-recipe-quantity",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("Recipequantity"))
                              ),
                            ]
                          ),
                          _vm.form.recipe
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "info flexbox m-top-10 font-weight-bold",
                                },
                                [
                                  _c("div", { staticClass: "flexbox" }, [
                                    _c(
                                      "div",
                                      { staticClass: "c-success m-right-10" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Transfer.stockOnHand"))
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedStock.baseUnitQuantity !==
                                            null
                                            ? _vm.selectedStock.baseUnitQuantity
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flexbox m-left-10" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "c-success m-right-10" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Dictionary.PortionQuantity"
                                              )
                                            ) + " :"
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.recipe.portionQuantity
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.form.recipe.portionUnitName
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "Form-item m-top-20" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(_vm._s(_vm.$t("Transfer.Transfer_info"))),
            ]),
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("customTextareaInput", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:512",
                      expression: "'max:512'",
                    },
                  ],
                  staticClass: "txt",
                  attrs: {
                    id: "input-transfer-detail-desription",
                    name: "description",
                    "data-vv-as": _vm.$t("Transfer.Transfer_info"),
                    error: _vm.veeErrors.has("description"),
                  },
                  model: {
                    value: _vm.form.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-transfer-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Transfer.transferDetail_formName"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                primary: "",
                variant: "full",
                size: "medium",
                id: "btn-transfer-popup-submit",
                type: "submit",
                form: "form-transfer-detail",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.showExpirationDateControl()
                              ? _vm.$t(
                                  "Transfer.updateTransferDetailAndEditExpDate"
                                )
                              : _vm.$t("Transfer.updateTransferDetail")
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.showExpirationDateControl()
                              ? _vm.$t(
                                  "Transfer.addTransferDetailAndEditExpDate"
                                )
                              : _vm.$t("Transfer.addTransferDetail")
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
          _c("ExpireDate", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showExpireDateEdit,
                expression: "showExpireDateEdit",
              },
            ],
            attrs: {
              data: _vm.ProductionOnTransferBatchList,
              transferHeader: _vm.transferHeaderInfo,
            },
            on: {
              editExpireDate: _vm.editExpireDate,
              getUpdatedBatch: _vm.editExpireDate,
              closeModal: _vm.closeExpireDateScreen,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }