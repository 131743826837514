var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    { on: { close: _vm.close } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(" " + _vm._s(_vm.$t("Transfer.updateTransfer")))]
            : [_vm._v(_vm._s(_vm.$t("Transfer.addTransferButton")))],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingForm ? _c("Loading") : _vm._e(),
          !_vm.isLoadingForm
            ? _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-transfer" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Transfer.senderStore"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|verify_inventory",
                              expression: "'required|verify_inventory'",
                            },
                          ],
                          attrs: {
                            "component-name":
                              "search-transfer-select-from-store",
                            name: "selectSenderStore",
                            "data-vv-as": _vm.$t("Transfer.senderStore"),
                            inputClass: {
                              "is-danger":
                                _vm.veeErrors.has("selectSenderStore"),
                            },
                            "on-search": _vm.getOptionsStore,
                            options: _vm.selectMiniStoreList,
                            label: "name",
                            loading: _vm.isSearchStoreLists,
                            disabled: _vm.isEdit,
                            placeholder: _vm.form.fromStoreId.name,
                            searchAll: true,
                            withDebounce: false,
                          },
                          on: {
                            input: _vm.inputSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.form.fromStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fromStoreId", $$v)
                            },
                            expression: "form.fromStoreId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("selectSenderStore"),
                              expression: "veeErrors.has('selectSenderStore')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("selectSenderStore"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Transfer.buyerStore"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|verify_inventory",
                              expression: "'required|verify_inventory'",
                            },
                          ],
                          attrs: {
                            "component-name":
                              "search-transfer-request-select-buyer-store",
                            name: "selectBuyerStore",
                            "data-vv-as": _vm.$t("Transfer.buyerStore"),
                            inputClass: {
                              "is-danger":
                                _vm.veeErrors.has("selectBuyerStore"),
                            },
                            "on-search": _vm.getOptionsToStore,
                            options: _vm.selectMiniToStoreList,
                            label: "name",
                            loading: _vm.isSearchStoreLists,
                            searchAll: true,
                            disabled: _vm.isEdit,
                            withDebounce: false,
                            placeholder: _vm.isEdit
                              ? _vm.form.toStoreId.name
                              : "",
                          },
                          on: {
                            input: _vm.inputToSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.form.toStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "toStoreId", $$v)
                            },
                            expression: "form.toStoreId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("selectBuyerStore"),
                              expression: "veeErrors.has('selectBuyerStore')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("selectBuyerStore"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Transfer.Transfer_Date"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("CustomDatepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|verify_inventory",
                              expression: "'required|verify_inventory'",
                            },
                          ],
                          class: {
                            "is-danger": _vm.veeErrors.has("transferDate"),
                          },
                          attrs: {
                            id: "transfer-header-datepicker",
                            name: "transferDate",
                            "data-vv-as": _vm.$t("Transfer.Transfer_Date"),
                            pickerType: "manuel",
                            inputIconRight: "icon-datepicker-big",
                            inputIcon: "",
                            disabled: _vm.isEdit,
                            disabledStartDate: _vm.isEdit
                              ? _vm.editDisableDate
                              : _vm.disableDate,
                          },
                          model: {
                            value: _vm.form.transferDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "transferDate", $$v)
                            },
                            expression: "form.transferDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inventoryValidation,
                            expression: "inventoryValidation",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.$t("Inventory.closedInventoryError")))]
                    ),
                  ]),
                  !_vm.isEdit
                    ? _c(
                        "div",
                        {
                          staticClass: "Form-item",
                          class: _vm.isOnlyListUsage ? "required" : "",
                        },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("Transfer.Form_List"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: _vm.isOnlyListUsage },
                                    expression: "{required: isOnlyListUsage}",
                                  },
                                ],
                                attrs: {
                                  "component-name": "transfer-form-list",
                                  "data-vv-as": _vm.$t("Transfer.Form_List"),
                                  "on-search": _vm.getOptionsRequestList,
                                  options: _vm.selectOptionsRList,
                                  label: "name",
                                  name: "transferList",
                                  error: _vm.veeErrors.has("transferList"),
                                  searchAll: true,
                                  placeholder: _vm.$t(
                                    "Transfer.Form_List_Placeholder"
                                  ),
                                },
                                model: {
                                  value: _vm.form.list,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "list", $$v)
                                  },
                                  expression: "form.list",
                                },
                              }),
                              _c("showValidateError", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.veeErrors.has("transferList"),
                                    expression: "veeErrors.has('transferList')",
                                  },
                                ],
                                attrs: {
                                  errorName:
                                    _vm.veeErrors.first("transferList"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(_vm.lists, function (item, index) {
                              return _c(
                                "Button",
                                {
                                  key: index,
                                  attrs: {
                                    seashell: "",
                                    size: "small",
                                    id: `selected-stock-item-${index}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeListItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _c("Icon", {
                                    staticClass: "m-left-5",
                                    attrs: {
                                      name: "icon-popup-close",
                                      id: `selected-stock-item-delete-${index}`,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Transfer.Transfer_info"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextareaInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:128",
                              expression: "'max:128'",
                            },
                          ],
                          staticClass: "txt",
                          attrs: {
                            id: "input-transfer-header-info",
                            name: "info",
                            "data-vv-as": _vm.$t("Transfer.Transfer_info"),
                            error: _vm.veeErrors.has("info"),
                          },
                          model: {
                            value: _vm.form.info,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "info", $$v)
                            },
                            expression: "form.info",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                size: "medium",
                variant: "full",
                primary: "",
                id: "btn-transfer-popup-submit",
                type: "submit",
                form: "form-transfer",
                disabled: _vm.isLoading || _vm.inventoryValidation,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Transfer.updateTransfer")))]
                    : [_vm._v(_vm._s(_vm.$t("Transfer.popupForm_Button_Add")))],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }