<template lang="pug">
Popup(@close="close")

    template(slot="title")
        template(v-if="isEdit")  {{ $t('Transfer.updateTransfer') }}
        template(v-else) {{ $t('Transfer.addTransferButton') }}

    template(slot="content")
      Loading(v-if="isLoadingForm")
      form.Form(
      v-if="!isLoadingForm"
      id="form-transfer"
      @submit.prevent="onSubmitForm")

        .Form-item.required
          label.Form-item-label
            | {{ $t('Transfer.senderStore') }}
          .control.form-item-select
            custom-search(
              component-name="search-transfer-select-from-store"
              name="selectSenderStore"
              v-model="form.fromStoreId"
              v-validate="'required|verify_inventory'",
              :data-vv-as="$t('Transfer.senderStore')"
              :inputClass="{ 'is-danger': veeErrors.has('selectSenderStore') }",
              :on-search="getOptionsStore"
              :options="selectMiniStoreList"
              label="name"
              :loading="isSearchStoreLists"
              :disabled="isEdit"
              :placeholder="form.fromStoreId.name"
              :searchAll="true"
              :withDebounce="false"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('selectSenderStore')"
              :errorName="veeErrors.first('selectSenderStore')"
            )        
        .Form-item.required
          label.Form-item-label
            | {{ $t('Transfer.buyerStore') }}
          .control.form-item-select
            custom-search(
              component-name="search-transfer-request-select-buyer-store"
              name="selectBuyerStore"
              v-model="form.toStoreId"
              v-validate="'required|verify_inventory'",
              :data-vv-as="$t('Transfer.buyerStore')"
              :inputClass="{ 'is-danger': veeErrors.has('selectBuyerStore') }",
              :on-search="getOptionsToStore"
              :options="selectMiniToStoreList"
              label="name"
              :loading="isSearchStoreLists"
              :searchAll="true"
              :disabled="isEdit"
              :withDebounce="false"
              :placeholder="isEdit ? form.toStoreId.name : ''"
              @input="inputToSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('selectBuyerStore')"
              :errorName="veeErrors.first('selectBuyerStore')"
            )              
              
        .Form-item.required
          label.Form-item-label {{ $t('Transfer.Transfer_Date') }}
          .control.form-item-select
            CustomDatepicker(
              id="transfer-header-datepicker"
              name="transferDate",
              v-model="form.transferDate",
              :class="{ 'is-danger': veeErrors.has('transferDate') }"
              :data-vv-as="$t('Transfer.Transfer_Date')",
              v-validate="'required|verify_inventory'",
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon=""
              :disabled="isEdit"
              :disabledStartDate="isEdit ? editDisableDate : disableDate")
          p.Form-item-help.is-danger(
            v-show="inventoryValidation"
          ) {{ $t('Inventory.closedInventoryError') }}

        .Form-item(v-if="!isEdit" :class="isOnlyListUsage ? 'required' : '' ")
          label.Form-item-label {{ $t('Transfer.Form_List') }}
          .control
            custom-search(
              component-name="transfer-form-list"
              v-model="form.list"
              :data-vv-as="$t('Transfer.Form_List') "
              :on-search="getOptionsRequestList"
              :options="selectOptionsRList"
              label="name"
              name="transferList"
              v-validate="{required: isOnlyListUsage}"
              :error="veeErrors.has('transferList')"
              :searchAll="true"
              :placeholder="$t('Transfer.Form_List_Placeholder')"
            )

            showValidateError(
              v-show="veeErrors.has('transferList')"
              :errorName="veeErrors.first('transferList')"
            )

          .selected-items
            Button(
              v-for="(item,index) in lists",
              :key="index",
              seashell,
              size="small",
              :id="`selected-stock-item-${index}`"
              @click="removeListItem(item)"
            )
              span {{ item.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`selected-stock-item-delete-${index}`"
              )

        .Form-item
          label.Form-item-label {{ $t('Transfer.Transfer_info') }}
          .control
            customTextareaInput.txt(
              id="input-transfer-header-info"
              name="info"
              :data-vv-as="$t('Transfer.Transfer_info')"
              v-validate="'max:128'"
              v-model="form.info"
              :error="veeErrors.has('info')"
            )

    template(slot="footer")

      Button.item(
        size="medium"
        variant="full"
        primary
      id="btn-transfer-popup-submit"
      type="submit",
      form="form-transfer",
      :disabled="isLoading || inventoryValidation")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Transfer.updateTransfer') }}
          template(v-else) {{ $t('Transfer.popupForm_Button_Add') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import CheckInventoryMixin from '@/mixins/validateInventory'
import customSelect from '@/view/global/custom-select'
import { vueWaitLoader } from '@/utils/baseOperations'

// import components

export default {
  name: 'TransferForm',
  mixins: [CheckInventoryMixin],
  props: {
    status: {
      type: String,
      default: 'new'
    },
    transferId: {
      type: String,
      default: null
    }
  },

  components: {
    customSelect
  },

  data () {
    return {
      disableDate: {
        from: new Date()
      },
      editDisableDate: {
        to: new Date(),
        from: new Date()
      },
      form: {
        list: null,
        fromStoreId: {
          id: '-1',
          name: ''
        },
        toStoreId: {
          id: '-1',
          name: ''
        },
        transferDate: new Date(),
        info: null
      },
      lists: [],
      isOnlyListUsage: false,
      isAvailableInventory: true,
      inventoryClosedMessage: null,
      selectOptionsRList: [],
      selectMiniStoreList: [],
      selectMiniToStoreList: [],
    }
  },
  computed: {
    ...mapGetters('Stores', [
      'miniStoreList',
      'sortedStoreList'
    ]),

    ...mapGetters('Settings', [
      'permissions',
      'TransferRequestStorePermissionList'
    ]),

    isEdit () {
      return this.status === 'edit'
    },

    isLoading () {
      return this.$wait.is(['transfer_header_create', 'transfer_header_update', 'onSubmitFormTransferHeader'])
    },
    isLoadingForm () {
      return this.$wait.is(['get_transfer_info', 'getMiniStoreList', 'getStoreList'])
    },
    checkInventoryParams () {
      /*
      *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
      */
      return {
        storeId: [this.form.toStoreId.id, this.form.fromStoreId.id],
        inventoryDate: moment(this.form.transferDate).format('YYYY-MM-DD')
      }
    },

    directSelectOptionStore () {
      if (this.isStoreSearch) return 
      return this.selectMiniStoreList[this.selectMiniStoreList.findIndex(item => item.id === this.form.fromStoreId.id)]
    },
    isStoreSearch () {
      return this.selectMiniStoreList.length === 1 || this.selectMiniToStoreList.length === 1
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
  },

  async mounted () {
    // edit mode on

    if (this.isEdit) {
      const transferInfo = await this.getTransfer(this.transferId)
      this.form.fromStoreId.id = transferInfo.data.transferHeader.fromStoreId
      this.form.fromStoreId.name = transferInfo.data.transferHeader.fromStoreName
      this.form.toStoreId.id = transferInfo.data.transferHeader.toStoreId
      this.form.toStoreId.name = transferInfo.data.transferHeader.toStoreName
      this.form.transferDate = transferInfo.data.transferHeader.transferDate
      this.form.info = transferInfo.data.transferHeader.info
    } else {
      await this.fetchUser().then(res => {
        this.form.fromStoreId.id = res.status === 200 ? res.data.user.defaultStoreId : -1
        this.form.fromStoreId.name = res.status === 200 ? res.data.user.defaultStoreName : ''
      }) // set default store in selectbox
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
    }
    this.selectMiniStoreList = this.miniStoreList
    this.selectMiniToStoreList = this.TransferRequestStorePermissionList
  },

  methods: {
    ...mapActions('ListsRequestTransfer', [
      'searchRequestTransferList'
    ]),

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('Transfer', [
      'createTransferHeader',
      'updateTransferHeader',
      'getTransfer',
      'checkInventoryForStore'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Stores', [
      'getMiniStoreList',
      'fetchStoreByName'
    ]),

    ...mapMutations('Transfer', [
      'HIDE_OTHERS_DETAILS',
      'SHOW_DETAIL'
    ]),

    ...mapMutations('Stores', [
      'RESET_STORE'
    ]),

    formReset () {
      this.form.fromStoreId.id = '-1'
      this.form.fromStoreId.name = ''
      this.form.toStoreId.id = '-1'
      this.form.toStoreId.name = ''
      this.form.info = null
      this.form.transferDate = new Date()
      this.form.list = null
      this.selectOptionsRList = []
      this.lists = []
      this.selectMiniStoreList = []
      this.selectMiniToStoreList = []
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.form.fromStoreId.id = data.id
        this.form.fromStoreId.name = data.name
      }
    },
    inputToSelection (data) {
      if (data.length !== 0) {
        this.form.toStoreId.id = data.id
        this.form.toStoreId.name = data.name
      }
    },

    resetOptions () {
      this.selectMiniStoreList = []
      this.selectMiniToStoreList = []
      this.getOptionsStore('')
      this.getOptionsToStore('')
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },

    getOptionsToStore (search) {
      this.fetchStoreByName({name: search, useTransferStorePermission: true})
        .then(res => {
          if (res) {
            this.selectMiniToStoreList = res.data.items
            const index = this.selectMiniToStoreList.findIndex(item => item.id === this.form.fromStoreId.id)
            if (index !== -1) this.selectMiniToStoreList.splice(index, 1)
          }
        })
    },

    close (val) {
      this.RESET_STORE()
      if (!val) {
        this.$router.push({
          name: this.$route.matched[0].name
        })
      }
    },

    async addNewTransfer () {
      if (this.form.toStoreId.id !== '-1') {
        const res = await this.createTransferHeader({
          fromStoreId: this.form.fromStoreId.id,
          toStoreId: this.form.toStoreId.id,
          info: this.form.info,
          list: this.form.list,
          transferDate: moment(this.form.transferDate).format('YYYY-MM-DD'),
          requestTransferListHeaderIds: [...this.lists.map(_ => _.id)]
        })
        if (res) {
          this.$emit('showDrafts')
          this.$emit('getList')
          const message = this.$t('Transfer.create_Notification_message')
          this.notifyShow({ message })
          this.close()
          if (this.lists.length === 0) {
            this.$router.push({
              name: 'NewTransferDetail',
              params: {
                id: res.data.id
              }
            })
          } else {
            const list = await this.getTransfer(res.data.id)
            this.SHOW_DETAIL(list.data)
          }
          this.HIDE_OTHERS_DETAILS(res.data)
        }
      }
    },
    async updateTransfer () {
      const requestData = {
        id: this.transferId,
        info: this.form.info
      }
      const res = await this.updateTransferHeader(requestData)
      if (res) {
        this.getTransfer(this.transferId)
        const message = this.$t('Transfer.update_Notification_message')
        this.notifyShow({message})
        this.close()
      }
    },
    onSubmitForm: vueWaitLoader(async function () {
      const validated = this.$validator.validateAll()
      if (validated) {
        if (this.isEdit) await this.updateTransfer()
        else await this.addNewTransfer()
      }
    }, 'onSubmitFormTransferHeader'),
    getOptionsRequestList (search) {
      this.searchRequestTransferList(search)
        .then(res => {
          this.selectOptionsRList = res.data.requestTransferListHeaderList
        })
    },
    removeListItem (item) {
      this.lists.splice(this.lists.indexOf(item), 1)
    }
  },

  watch: {
    async 'form.list' (value) {
      if (!value || this.lists.some(item => item.id === value.id)) return
      this.lists.push(value)
      this.$nextTick(() => {
        this.form.list = null
      })
    },
    'form.fromStoreId.id' (val) {
      const index = this.selectMiniToStoreList.findIndex(item => item.id === val)
      if (index !== -1) this.selectMiniToStoreList.splice(index, 1)
    },
    
  }

}
</script>

<style lang="scss" scoped>

  .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
