import BaseClass from '@/utils/baseclass'
import moment from 'moment'

export default class TransferBatch extends BaseClass {
  addItem(item) {
    let newItem = new Batch(item)
    this.list.push(newItem)
  }
  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.fromStoreId = item.fromStoreId
      findItem.fromStoreName = item.fromStoreName
      findItem.fromStoreName = item.fromStoreName
      findItem.quantity = item.quantity
      findItem.showDetail = false
      findItem.remainingQuantity = item.remainingQuantity
      findItem.requestedQuantity = item.requestedQuantity
      findItem.shelfLife = item.shelfLife
      findItem.stockItemId = item.stockItemId
      findItem.stockItemName = item.stockItemName
      findItem.toStoreId = item.toStoreId
      findItem.toStoreName = item.toStoreName
      findItem.unitCode = item.unitCode
      findItem.detailList = []
    }
  }
}
export class Batch {
  constructor(item) {
    this.id = item.id
    this.fromStoreId = item.fromStoreId
    this.fromStoreName = item.fromStoreName
    this.fromStoreName = item.fromStoreName
    this.quantity = item.quantity
    this.showDetail = false
    this.remainingQuantity = item.remainingQuantity
    this.requestedQuantity = item.requestedQuantity
    this.shelfLife = item.shelfLife
    this.stockItemId = item.stockItemId
    this.stockItemName = item.stockItemName
    this.toStoreId = item.toStoreId
    this.toStoreName = item.toStoreName
    this.unitCode = item.unitCode
    this.detailList = []
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new BatchDetail(detail, this.id, this.remainingQuantity))
    })
  }
  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }
}

export class BatchDetail {
  constructor(detail, transferDetailId, headerQuantity) {
    this.degree = detail.degree
    this.expirationDate = detail.expirationDate
    this.givenQuantity = detail.givenQuantity
    this.id = detail.id
    this.transferDetailId = transferDetailId
    this.lotNumber = detail.lotNumber
    this.headerRemainingQuantity = headerQuantity
    this.quantity = detail.quantity
    this.shelfStorageLocationListHeaderId = detail.shelfStorageLocationListHeaderId
    this.shelfStorageLocationListHeaderName = detail.shelfStorageLocationListHeaderName
    this.shipmentTime = detail.shipmentTime
    this.warehouseTime = detail.warehouseTime
    this.unitCode = detail.unitCode
  }
}
