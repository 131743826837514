import BaseClass from "@/utils/baseclass";

export default class Transfers extends BaseClass {
  addItem(item) {
    let newItem = new Transfer(item);
    this.list.push(newItem);
  }

  updateItem(item) {
    let findItem = this.findItem(item.id);
    if (findItem) {
      findItem.id = item.id;
      findItem.code = item.code;
      findItem.fromStoreId = item.fromStoreId;
      findItem.fromStoreName = item.fromStoreName;
      findItem.info = item.info;
      findItem.showDetail = item.showDetail;
      findItem.status = item.status;
      findItem.statusType = item.status;
      findItem.toStoreId = item.toStoreId;
      findItem.toStoreName = item.toStoreName;
      findItem.transferDate = item.transferDate;
      findItem.totalAmount = item.totalAmount;
      findItem.rejectionReason = item.rejectionReason;
      findItem.info = item.info;
      findItem.maxApprovalLevel = item.maxApprovalLevel;
      findItem.currentApprovalLevel = item.currentApprovalLevel;
      findItem.approvalStatus = item.approvalStatus;
      findItem.despatchNumber = item.despatchNumber;
      findItem.despatchHeaderId = item.despatchHeaderId;
    }
  }
}

export class Transfer {
  constructor(item) {
    this.id = item.id;
    this.code = item.code;
    this.fromStoreId = item.fromStoreId;
    this.fromStoreName = item.fromStoreName;
    this.info = item.info;
    this.status = item.status;
    this.statusType = item.status;
    this.toStoreId = item.toStoreId;
    this.toStoreName = item.toStoreName;
    this.transferDate = item.transferDate;
    this.detailList = [];
    this.showDetail = false;
    this.totalAmount = item.totalAmount;
    this.rejectionReason = item.rejectionReason;
    this.info = item.info;
    this.maxApprovalLevel = item.maxApprovalLevel;
    this.currentApprovalLevel = item.currentApprovalLevel;
    this.approvalStatus = item.approvalStatus;
    this.despatchNumber = item.despatchNumber;
    this.despatchHeaderId = item.despatchHeaderId;
  }

  addDetails(list) {
    this.detailList = [];
    list.forEach((detail) => {
      this.detailList.push(
        new TransferDetail({
          detail,
          approvalStatus: this.approvalStatus,
          fromStoreId: this.fromStoreId,
        })
      );
    });
  }

  get isLive() {
    return this.status === 2;
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id);
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1);
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex((item) => item.id === Id);
  }

  findItem(Id) {
    return this.detailList.find((item) => item.id === Id);
  }

  get detailIsEmpty() {
    return this.detailList.length === 0;
  }
}

export class TransferDetail {
  constructor({ detail, approvalStatus, fromStoreId }) {
    this.type = detail.type;
    this.baseQuantity = detail.baseQuantity;
    this.baseUnitCode = detail.baseUnitCode;
    this.baseUnitId = detail.baseUnitId;
    this.baseUnitPrice = detail.baseUnitPrice;
    this.id = detail.id;
    this.quantity = detail.quantity;
    this.requestQuantity = detail.requestQuantity;
    this.stockItemId = detail.stockItemId;
    this.recipeHeaderId = detail.recipeHeaderId;
    this.name = detail.name;
    this.transferHeaderId = detail.transferHeaderId;
    this.unitCode = detail.unitCode;
    this.unitId = detail.unitId;
    this.unitPrice = detail.unitPrice;
    this.totalAmount = detail.totalAmount;
    this.description = detail.description;
    this.toStoreStockOnHandQuantity = detail.toStoreStockOnHandQuantity;
    this.fromStoreStockOnHandQuantity = detail.fromStoreStockOnHandQuantity;
    this.approvalStatus = approvalStatus;
    this.isEditable = true;
    this.isDeleteable = true;
    this.fromStoreId = fromStoreId;
    this.price = detail.price;
    this.productionQuantity = detail.productionQuantity;
    this.productionUnitId = detail.productionUnitId;
    this.expirationDateControlIsEnabled = detail.expirationDateControlIsEnabled;
  }
}
