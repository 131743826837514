<template lang="pug">
Popup(@close="close")

    template(slot="title") {{ formTitle }}

    template(slot="detail")
      div.Popup-despatch-info.row(id="description-transfer-detail")
        div(id="description-transfer-no-title") {{ $t('Transfer.Transfer_No') }} :
        div.ff-mono(id="description-transfer-no") {{ transferHeaderInfo.code }}

    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(v-model="type", :data="[{text: $t('Dictionary.StockItem'), value: 1 }, { text: $t('Dictionary.Recipe'), value: 2 }]")
      Loading(v-if="isLoadingForm")
      form.Form(
      v-if="!isLoadingForm"
      id="form-transfer-detail"
      @submit.prevent="onSubmitFormTransfer")

        div(v-if="isFormTypeStock")
          .Form-item.required
            label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_StockItemName') }}
            .control
              custom-search(
                component-name="transfer-detail-stock-item"
                ref="firstInput"
                name="stockItem"
                v-model="form.stockItem"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_StockItemName')"
                v-validate="{  required: isFormTypeStock, verify_inventory: true}"
                :inputClass="{ 'is-danger': veeErrors.has('stockItem'), 'verify_inventory': true }"
                :on-search="getOptions"
                :options="selectOptions"
                :disabled="isEdit"
                label="name"
                :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
                :directSelectOption="directSelectOption"
                @resetOptions="resetSearch"
              )

            p.Form-item-help.is-danger(
              id="validate-message-transfer-detail-stock-item"
              v-show="veeErrors.has('stockItem')"
            ) {{ veeErrors.first('stockItem') }}

            .Form-item-info(v-if="form.stockItem")
              div
                .text {{ $t('Transfer.stockAmount') }}
                .value
                  span {{selectedStock.baseUnitQuantity}} {{ selectedStock.baseUnitName}}
          .Form-item.required.row-field
            .item
              label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}
              .control
                customNumberInput(
                  id="input-transfer-detail-form-quantity"
                  name="quantity"
                  :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
                  v-model="form.quantity"
                  :disabled="!form.stockItem"
                  v-validate="allowNegativeStock ? { required: isFormTypeStock, decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')} : { required: isFormTypeStock, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:-1, max_value: !canProduction ? selectedStock.baseUnitQuantity : 999999999999999}",
                  :class="{ 'is-danger': veeErrors.has('quantity') }"
                  @input="onChangeQuantity"
                )
              
                showValidateError(
                  v-show="veeErrors.has('quantity')"
                  :errorName="veeErrors.first('quantity')"
                )
   
            .item
              label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
              .control              
                CustomSelect(
                  id="select-transfer-popup-select-unit"
                  name="unit"
                  :optionData="filteredUnitList"
                  v-model="form.unitId"
                  :isSearchActive="true"
                  :data-vv-as="$t('Dictionary.Unit')"
                  :error="veeErrors.has('unit')"
                  :isDefaultTextActive="true"
                  v-validate="'required|is_not:-1'"
                  :defaultTitle="$t('Transfer.selectUnit')"
                  optionTitle="name"
                  selectedEmitValue="id"
                  :isDisabled="!form.stockItem"
                  @inputSelected="onStockChange"
                )

                showValidateError(
                  v-show="veeErrors.has('unit')"
                  :errorName="veeErrors.first('unit')"
                )
         
          .Form-item.row-field(v-if="isEdit ? (stockItemType === 2 && canProduction) : (canProduction && form.stockItem?.type === 2)")
            .item
              label.Form-item-label(:id="`transfer-detail-popup-form-label-productionquantity`") {{ $t('Transfer.detail_Popup_FormField_ProductionQuantity') }}
              .control
                customNumberInput(
                  id="transfer-detail-productionQuantity"
                  name="productionQuantity"
                  v-model="form.productionQuantity"
                  v-validate="{min_value:0}"
                  :error="veeErrors.has('productionQuantity')"
                  :data-vv-as="$t('Transfer.detail_Popup_FormField_ProductionQuantity')"
                  :disabled="!form.quantity"
                )

                showValidateError(
                  v-show="veeErrors.has('productionQuantity')"
                  :errorName="veeErrors.first('productionQuantity')"
                )

                .Form-item-info(v-if="form.stockItem")
                  div
                    .text {{ $t('Transfer.baseUnitName') }}
                    .value
                      span {{selectedStock.baseUnitName}}
            .item
              label.Form-item-label
                | {{ $t('Transfer.ProductionUnit') }}
              .control.form-item-select
                CustomSelect(
                  name="productionUnit"
                  :optionData="filteredUnitList"
                  v-model="form.productionUnitId"
                  :data-vv-as="$t('Transfer.ProductionUnit')"
                  :isSearchActive="true"
                  :error="veeErrors.has('productionUnit')"
                  :isDefaultTextActive="false"
                  :defaultTitle="$t('Transfer.selectProductionUnit')"
                  optionTitle="name"
                  selectedEmitValue="id"
                  id="transfer-select-productionUnit"
                  :isDisabled="!form.productionQuantity && form.productionQuantity !== 0"
                )

                showValidateError(
                  v-show="veeErrors.has('productionUnit')"
                  :errorName="veeErrors.first('productionUnit')"
                )
          
          .Form-item   
            .item   
              label.Form-item-label
                | {{ $t('Despatches.detail_Popup_FormField_Price') }}

              customNumberInput(
                id="transfer-detail-price"
                name="price"
                :error="veeErrors.has('price')"
                v-validate="{min_value: 0}"
                :disabled="selectedStock.listPriceType !== 2 && selectedStock.listPriceType !== 3"
                :placeholder="$t('Despatches.detail_Popup_FormField_Price')"
                :data-vv-as="$t('Despatches.detail_Popup_FormField_Price')"
                v-model="form.price"
                @input="changePrice"
              )

        template(v-else)
          .Form-item.required
            label.Form-item-label {{ $t('Dictionary.Recipe') }}
            .control
              custom-search(
                component-name="transfer-detail-recipe"
                name="recipe"
                ref="firstInputRecipe"
                v-model="form.recipe"
                :data-vv-as="$t('Dictionary.Recipe')"
                v-validate="{ required: !isFormTypeStock }",
                :disabled="isEdit"
                :inputClass="{ 'is-danger': veeErrors.has('recipe') }",
                :on-search="getRecipeOptions",
                :options="selectedOptionsRecipe",
                label="name",
                :placeholder="$t('ListsWaste.detail_Popup_FormField_RecipeName_Placeholder')"
                :directSelectOption="recipeDirectSelectOption"
                @afterSelected="setRecipe"
              )

            p.Form-item-help.is-danger(
            id="validate-message-transfer-detail-recipe"
            v-show="veeErrors.has('recipe')"
            ) {{ veeErrors.first('recipe') }}

          .Form-item.required
            label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}
            .control
              input.txt(
              name="Recipequantity"
              id="input-transfer-detail-form-recipe-quantity"
              autocomplete="off"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
              v-model.number="form.quantity"
              v-validate="{ required: !isFormTypeStock, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0 }",
              :class="{ 'is-danger': veeErrors.has('Recipequantity') }"
              type="number"
              step="any")

            
            p.Form-item-help.is-danger(
            id="validate-message-transfer-detail-recipe-quantity"
            v-show="veeErrors.has('Recipequantity')"
            ) {{ veeErrors.first('Recipequantity') }}

            .info.flexbox.m-top-10.font-weight-bold(v-if="form.recipe")
              .flexbox
                .c-success.m-right-10 {{ $t('Transfer.stockOnHand') }}
                .value {{ selectedStock.baseUnitQuantity !== null ? selectedStock.baseUnitQuantity : '-' }}
              .flexbox.m-left-10
                .c-success.m-right-10 {{ $t('Dictionary.PortionQuantity') }} :
                .value {{ form.recipe.portionQuantity }} {{ form.recipe.portionUnitName }}

      .Form-item.m-top-20
        label.Form-item-label {{ $t('Transfer.Transfer_info') }}
        .control
          customTextareaInput.txt(
            id="input-transfer-detail-desription"
            name="description"
            v-validate="'max:512'"
            :data-vv-as="$t('Transfer.Transfer_info')"
            v-model="form.description"
            :error="veeErrors.has('description')"
          )
    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-transfer-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Transfer.transferDetail_formName') })"
        v-model="saveAndNew")

      Button.item(
        primary
        variant="full"
        size="medium"
      id="btn-transfer-popup-submit"
      type="submit",
      form="form-transfer-detail",
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ showExpirationDateControl() ? $t('Transfer.updateTransferDetailAndEditExpDate') : $t('Transfer.updateTransferDetail') }}
          template(v-else) {{ showExpirationDateControl() ? $t('Transfer.addTransferDetailAndEditExpDate') : $t('Transfer.addTransferDetail') }}
        Loading(theme="disable", v-show="isLoading")
  
      ExpireDate(
        v-show="showExpireDateEdit"
        :data="ProductionOnTransferBatchList"
        :transferHeader="transferHeaderInfo"
        @editExpireDate="editExpireDate"
        @getUpdatedBatch="editExpireDate"
        @closeModal="closeExpireDateScreen"
      )
  
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CheckInventoryMixin from '@/mixins/validateInventory'
import { vueWaitLoader } from '@/utils/baseOperations'
// import components
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import moment from 'moment'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'
import ExpireDate from '../components/expire-date-table' 
import CustomSelect from '@/view/global/custom-select'


export default {
  name: 'TransformDetailForm',
  mixins: [CheckInventoryMixin, getQuantityFromBarcode],

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        stockItem: null,
        recipe: null,
        quantity: null,
        unitId: null,
        description: null,
        price: null,
        productionQuantity: null,
        productionUnitId: null
      },
      type: 1,
      transferHeaderInfo: {},
      stockItem: {},
      selectedStock: {},
      selectedOptionsRecipe: [],
      selectOptions: [],
      saveAndNew: true,
      stockItemInfo: {},
      allowNegativeStock: false,
      currentPrice: null,
      recipeSearch: null,
      canProduction: false,
      stockItemType: null,
      selectedTransferDetailId: null,
      storeHasExpDateControl: false,
      stockItemHasExpDateControl: false,
      showExpireDateEdit: false,
      dates: []
    }
  },

  components: {
    ExpireDate,
    CustomSelect
  },

  watch: {
    async 'form.stockItem' (val) {
      if (this.type === 1) await this.getStockData(val.id)
      if (this.isEdit || !val) return
      await this.setUnit(val.unitId)
      this.onStockChange()
    },
    'form.productionUnitId' () {
      this.onChangeQuantity()
    },
    'form.unitId' () {
      this.onChangeQuantity()
    },
    selectedStock () {
      this.onChangeQuantity()
    },
    type () {
      this.formReset()
      this.$nextTick(() => {
        this.$validator.reset()
        this.focusFirstInput()
      })
    }
  },
  computed: {
    ...mapGetters('Units', ['UnitList', 'findUnit', 'getRelatedUnitList']),
    ...mapGetters('Transfer', ['Page', 'ProductionOnTransferBatchList']),

    isEdit () {
      return this.status === 'edit'
    },
    formTitle () {
      return this.isEdit ? this.$t('Transfer.updateTransferDetail') : this.$t('Transfer.addTransferDetail')
    },
    isFinalized () {
      return this.transferHeaderInfo.status === '2'
    },
    filteredUnitList () {
      return this.form.stockItem ? this.UnitList.filter(item => (item.baseUnitId === this.form.stockItem.baseUnitId) && (item.isRecipeUnit === false)) : []
    },
    isFormTypeStock () {
      return this.type === 1
    },
    isLoading () {
      return this.$wait.is(['transfer_detail_create', 'transfer_detail_update', 'onSubmitFormTransferDetail'])
    },
    isLoadingForm () {
      return this.$wait.is(['get_transfer_info', 'get_transfer_detail_list'])
    },

    transferDate () {
      return this.transferHeaderInfo.code.slice(0, 4) + '-' + this.transferHeaderInfo.code.slice(4, 6) + '-' + this.transferHeaderInfo.code.slice(6, 8)
    },
    checkInventoryParams () {
      /*
      *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
      */
      return {
        storeId: [this.transferHeaderInfo.toStoreId, this.transferHeaderInfo.fromStoreId],
        inventoryDate: this.transferHeaderInfo.transferDate,
        stockItemIdList: [this.form.stockItem.id]
      }
    },

    isBarcodeSearch () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
    },

    isRecipeBarcodeSearch () {
      return this.selectedOptionsRecipe.length === 1 &&
        this.selectedOptionsRecipe[0].matchType === 2
    },

    directSelectOption () {
      if (this.form.stockItem && !this.isEdit) this.form.unitId = this.form.stockItem.unitId
      return this.isBarcodeSearch
        ? this.selectOptions[0]
        : {}
    },
    recipeDirectSelectOption () {
      return this.isRecipeBarcodeSearch
        ? this.selectedOptionsRecipe[0]
        : {}
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('Transfer', [
      'getTransfer',
      'createTransferDetail',
      'updateTransferDetail',
      'getTransferDetail',
      'getTransferBatchData',
      'getProductionOnTransferBatchData',
      'getStockInfo',
      'getTransferList']),

    ...mapActions('Stores', ['getStore']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Recipes', ['searchRecipeItemByName', 'get_RECIPE_HEADER']),
    ...mapMutations('Transfer', ['SHOW_DETAIL', 'HIDE_OTHERS_DETAILS']),
    ...mapMutations('Stores', [
      'RESET_STORE'
    ]),

    async setRecipe (option) {
      await this.get_RECIPE_HEADER(option.id)
        .then(res => {
          this.form.recipe = res.data.recipeHeader
          if (this.isRecipeBarcodeSearch) {
            const unitId = res.data.recipeHeader.totalUnitId
            const search = this.recipeSearch
            const parsedBarcode = this.getQuantityFromBarcode(search, unitId)
            if (parsedBarcode.isWeighted) {
              this.form.quantity = parsedBarcode.quantity
            }
          }
        })
      const payload = {
        storeId: this.transferHeaderInfo.fromStoreId,
        stockItemId: this.form.recipe.id,
        unitId: this.form.recipe.unitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }

      await this.getStockInfo(payload)
        .then(res => {
          this.stockItemInfo = res.data
        })
    },

    async editExpireDate () {
       const payload = {
        transferDetailIds: this.isEdit ? [this.$route.params.detailId] : [this.selectedTransferDetailId]
      }
      await this.getTransferBatchData(payload)
      this.showExpireDateEdit = true
    },

    showExpirationDateControl () {
      return this.storeHasExpDateControl && this.stockItem.expirationDateControlIsEnabled && this.stockItem.type === 2
    },

    getStoreInfo () {
      this.getStore(this.transferHeaderInfo.fromStoreId).then(res => {
        this.allowNegativeStock = res.data.store.allowNegativeStock
        this.canProduction = res.data.store.canProductionOnTransfer
        this.storeHasExpDateControl = res.data.store.expirationDateControlIsEnabled
        this.showExpirationDateControl()
      })
    },

    resetSearch () {
      this.form.stockItem = null
      this.selectOptions = []
    },

    focusFirstInput () {
      const focusableInput = this.isFormTypeStock ? this.$refs.firstInput : this.$refs.firstInputRecipe
      focusableInput.$refs.search.focus()
    },

   async getRecipeOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.recipeSearch = search
      await this.searchRecipeItemByName({ name: decoratedValue, types: [1, 2, 3] })
        .then(res => {
          this.selectedOptionsRecipe = res.data.recipeHeaderList
          if (this.isRecipeBarcodeSearch) {
            this.setRecipe(this.selectedOptionsRecipe[0])
          }
        })
    },
    getOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.searchStockItem({ Text: decoratedValue, types: [1, 2, 3] })
        .then(res => {
          this.selectOptions = res.data.items
          if (this.isBarcodeSearch) {
            const unitId = res.data.items[0].unitId
            const parsedBarcode = this.getQuantityFromBarcode(search, unitId)
            if (parsedBarcode.isWeighted) {
              this.form.quantity = Number(parsedBarcode.quantity)
            }
          }
        })
    },
    async getStockData (id) {
      await this.getStockItem(id).then(res => {
        if (res.status === 200) {
          this.stockItem = res.data.stockItem
          this.stockItemType = this.stockItem.type
          this.showExpirationDateControl()
        }
      })
    },
    setUnit (unitId) {
      this.form.unitId = unitId
      this.form.productionUnitId = unitId
    },

    close (val) {
      this.RESET_STORE()
      if (!val) {
        this.$router.push({
          name: this.$route.matched[0].name
        })
      }
    },
    formReset () {
      this.form = {
        stockItem: null,
        quantity: null,
        unitId: '-1',
        recipe: null,
        description: null,
        price: null,
        productionQuantity: null
      }
      this.selectOptions = []
      this.selectedOptionsRecipe = []
    },
    async newTransferDetail () {
      const payload = {
        quantity: this.form.quantity,
        transferHeaderId: this.transferHeaderInfo.id,
        type: this.type,
        description: this.form.description,
        productionQuantity: this.isFormTypeStock && this.canProduction && this.stockItemType === 2 ? this.form.productionQuantity : null,
        productionUnitId: this.isFormTypeStock && this.canProduction && this.stockItemType === 2 ? this.form.productionUnitId : null,
        price: this.form.price && (this.selectedStock.listPriceType === 2 || this.selectedStock.listPriceType === 3) ? this.form.price : null
      }
      if (this.isFormTypeStock) {
        payload.stockItemId = this.form.stockItem.id
        payload.unitId = this.form.unitId
      } else {
        payload.recipeHeaderId = this.form.recipe.id
        payload.unitId = this.form.recipe.totalUnitId
      }

      const res = await this.createTransferDetail(payload)
      if (res) {
        this.selectedTransferDetailId = res.data.id
        const message = this.$t('Transfer.detail_create_Notification_message')
        this.notifyShow({ message })
        const list = await this.getTransfer(this.$route.params.id)
        this.SHOW_DETAIL(list.data)
        if (this.showExpirationDateControl()) {
          this.showExpireDateEdit = true
          this.editExpireDate()
        }
        else {
          if (this.saveAndNew) {
            this.$nextTick(async () => {
              await this.formReset()
              await this.$validator.reset()
              await this.focusFirstInput()
            })
          } else this.close()
        }
      }
    },

    async closeExpireDateScreen () {
      this.showExpireDateEdit = false
      if (this.saveAndNew && !this.isEdit) {
        this.$nextTick(async () => {
          await this.formReset()
          await this.$validator.reset()
          await this.focusFirstInput()
        })
        return
      }
      this.close()
    },

    changePrice (item) {
      this.currentPrice = item
    },

    onChangeQuantity () {
      const baseQuantity = this.form.quantity * this.findUnit(this.form.unitId)?.baseQuantity
      const baseUnitQuantity = this.isEdit ? this.form.baseQuantity : this.selectedStock.baseUnitQuantity
      const baseProductionQuantity = this.findUnit(this.form.productionUnitId)?.baseQuantity
      this.form.productionQuantity = Math.max((baseQuantity - baseUnitQuantity) / baseProductionQuantity , 0);
    },

    async editTransferDetail (item) {
      const payload = {
        unitId: this.form.unitId,
        quantity: this.form.quantity,
        transferDetailId: this.$route.params.detailId,
        description: this.form.description,
        productionQuantity: this.canProduction && this.stockItemType === 2 ? this.form.productionQuantity : null,
        productionUnitId: this.canProduction && this.stockItemType === 2 ? this.form.productionUnitId : null,
        price: this.currentPrice
      }
      const updated = await this.updateTransferDetail(payload)
      const message = this.$t('Transfer.detail_update_Notification_message')
      if (updated) {
        this.notifyShow({ message })
      }
      const res = await this.getTransfer(this.$route.params.id)
      this.SHOW_DETAIL(res.data)
      if (this.showExpirationDateControl()) {
        this.showExpireDateEdit = true
        this.editExpireDate()
      }
      else this.close()
    },

    onSubmitFormTransfer: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      if (this.isEdit) await this.editTransferDetail()
      else await this.newTransferDetail()
      
    }, 'onSubmitFormTransferDetail'),

    async onStockChange () {
      if (this.type === 1) {
        const requestData = {
          StoreId: this.transferHeaderInfo.fromStoreId,
          StockItemId: this.stockItem.id,
          UnitId: this.form.unitId,
          forDate: moment(new Date()).format('YYYY-MM-DD'),
          toStoreId: this.transferHeaderInfo.toStoreId
        }
        const res = await this.getStockInfo(requestData)
        const unit = await this.filteredUnitList.find(item => item.code === res.data.unitCode)
        const unitName = unit ? unit.name : ''
        this.selectedStock = ({
          ...res.data,
          unitName
        })
      }
      if (!this.isEdit) this.form.price = this.selectedStock.unitPrice
    }

    /* async fetchStockItems() {
      this.stockItemInfo = {}
      const payload = {
        storeId: this.transferHeaderInfo.fromStoreId,
        stockItemId: this.form.stockItem.id,
        unitId: this.form.stockItem.baseUnitId,
        forDate: moment(new Date()).format('YYYY-MM-DD'),
        toStoreId: this.transferHeaderInfo.toStoreId
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },
 */
  },
  async mounted () {
    this.dates = getDateFilterSetting('Date_Transfer')
    const transferInfo = await this.getTransfer(this.$route.params.id)
    this.transferHeaderInfo = ({
      ...transferInfo.data.transferHeader
    })
    if (this.isEdit) {
      const res = await this.getTransferDetail(this.$route.params.detailId)
      if (res) {
        const transferDetail = res.data.transferDetail
        this.type = await transferDetail.type
        this.form = await {
          ...transferDetail,
          stockItem: {
            id: transferDetail.stockItemId,
            name: transferDetail.name,
            baseUnitId: transferDetail.baseUnitId,
            fromStoreId: transferDetail.fromStoreId
          },
          recipe: {
            id: transferDetail.recipeHeaderId,
            name: transferDetail.name,
            unitId: transferDetail.unitId,
            portionUnitId: transferDetail.portionUnitId,
            portionUnitName: transferDetail.portionUnitName,
            portionQuantity: transferDetail.portionUnitQuantity

          }
        }
        this.stockItem = {
          id: transferDetail.stockItemId,
          name: transferDetail.name,
          unitId: transferDetail.unitId
        }
        //  this.fetchStockItems()
        this.onStockChange()
      }
    }
    await this.getStoreInfo()
    this.focusFirstInput()
  }
}
</script>

<style lang="scss">

.Popup-transfer-info {
  margin-top: 21px;
  font-weight: $font-weight-bold;
  display: flex;
  &.row {
    flex-direction: column;
    justify-content: center;
  }
  div {
    margin-right: 30px;
    margin-top: 9px;
    svg {
      color: $color-success;
      margin-right: 5px;
    }
  }
}
.Form-item {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.Form-item-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  font-size: $font-size-small;

  > div {
    display: flex;
    margin-right: 20px;
    align-items: center;

    select {
      margin-bottom: -2px;
      border: 0;
      background-color: $color-gray;
    }
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
    &:not(:last-child) {
      margin-right: 1.6%
    }
  }
}

.Form-item-info-multiple {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  flex-wrap: wrap;
  margin-top: 17px;

  &-row {
    display: flex;
    justify-content: space-between;
    border: solid $color-gray 1px;
    width: 100%;
    padding: 17px 10px;
  }

  &-row:first-child {
    border-bottom: none;
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
  }
}

.margin-discount {
  margin-top: 17px;
}
.row-field {
  justify-content: space-between;
  .item {
    width: 100%;
  }
}

</style>
