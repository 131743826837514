<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Transfer.transferDetail_formName')",
  loading="transfer_detail_delete",
  :isGetDataLoading="$wait.is('isFetchingData')"
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveTransferDetailAlert',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Transfer.detail_field_name'),
        title: this.$t('Transfer.detail_field_name')
      }, {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Despatches.detail_TableColumn_Quantity'),
        title: this.$t('Despatches.detail_TableColumn_Quantity')
      }, {
        name: 'totalAmount',
        is_visible: true,
        short_title: this.$t('Transfer.detail_TableColumn_Price'),
        title: this.$t('Transfer.detail_TableColumn_Price')
      }],
      item: {},
      transferHeaderInfo: {},
      isDeletable: true
    }
  },

  async mounted () {
    this.$wait.start('isFetchingData')
    const transferInfo = await this.getTransfer(this.$route.params.id)
    const transferDetailInfo = await this.getTransferDetail(this.$route.params.detailId)
    const transferHeader = transferInfo.data.transferHeader
    this.transferHeaderInfo = transferHeader
    this.item = transferDetailInfo.data.transferDetail
    this.isDeletable = transferHeader.status === 1
    this.$wait.end('isFetchingData')
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Transfer', [
      'getTransferList',
      'getTransfer',
      'getTransferDetail',
      'deleteTransferDetail'
    ]),

    ...mapMutations('Transfer', [
      'SHOW_DETAIL',
      'UPDATE_TRANSFER_ITEM'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteTransferDetail(this.$route.params.detailId)
      if (res) {
        const message = this.$t('Transfer.detail_Delete_Notification_message', {
          name: this.item.name
        })
        this.notifyShow({ message })
        const transfer = await this.getTransfer(this.$route.params.id)
        this.UPDATE_TRANSFER_ITEM(transfer.data.transferHeader)
        this.SHOW_DETAIL(transfer.data)
        this.close()
      }
    }, 'itemRemoveTransferDetail')
  }
}
</script>
