<template lang="pug">
DeleteAlert(
  :item="itemWithFormattedDate",
  :fields="fields",
  :tableName="$t('Transfer.Transfer_Entry')",
  loading="transfer_header_delete",
  :isGetDataLoading="$wait.is('isFetchingData')"
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveTransferAlert',

  data () {
    return {
      fields: [{
        name: 'fromStoreName',
        is_visible: true,
        short_title: this.$t('Transfer.senderStore'),
        title: this.$t('Transfer.senderStore')
      }, {
        name: 'toStoreName',
        is_visible: true,
        short_title: this.$t('Transfer.buyerStore'),
        title: this.$t('Transfer.buyerStore')
      }, {
        name: 'transferDateFormatted',
        is_visible: true,
        short_title: this.$t('Transfer.Transfer_Date'),
        title: this.$t('Transfer.Transfer_Date')
      }],
      item: {},
      isDeletable: true
    }
  },

  async mounted () {
    this.$wait.start('isFetchingData')
    this.getTransfer(this.$route.params.id)
      .then(res => {
        this.item = res.data.transferHeader
        this.isDeletable = this.item.status === 1
      })
    this.$wait.end('isFetchingData')
  },

  computed: {
    itemWithFormattedDate () {
      const transferDateFormatted = new Date(this.item.transferDate).toLocaleDateString('tr')
      return Object.assign({}, this.item, {
        transferDateFormatted
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Transfer', [
      'getTransferList',
      'getTransfer',
      'deleteTransfer'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      if (!this.isDeletable) {
        alert(this.$t('Global.itemCantBeDeleted'))
        return
      }
      const res = await this.deleteTransfer(this.$route.params.id)
      if (res) {
        const message = this.$t('Transfer.delete_Notification_message', {
          date: this.itemWithFormattedDate.transferDateFormatted
        })
        this.notifyShow({ message })
        this.$emit('getList')
        this.close()
      }
    }, 'itemRemoveTransferHeader')
  }
}
</script>
