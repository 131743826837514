var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeleteAlert", {
    attrs: {
      item: _vm.itemWithFormattedDate,
      fields: _vm.fields,
      tableName: _vm.$t("Transfer.Transfer_Entry"),
      loading: "transfer_header_delete",
      isGetDataLoading: _vm.$wait.is("isFetchingData"),
    },
    on: { delegateOnAlertRemoveOk: _vm.itemRemove },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }