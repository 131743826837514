import HTTP from '@/utils/http'
import { isEmpty } from '@/utils/baseOperations'
import moment from 'moment'

import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'

import Transfers from '../model'
import TransferBatch from '../model/transfer-batch'
import ProductionOnTransferBatch from '../model/production-on-transfer-batch'

export default {
  namespaced: true,
  state: {
    Transfer: new Transfers(),
    Batch: new TransferBatch(),
    ProductionOnTransferBatch: new ProductionOnTransferBatch(),
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    isEmptyTransferList: state => isEmpty(state.Transfer.list),
    TransferList: state => state.Transfer.list,
    BatchList: state => state.Batch.list,
    ProductionOnTransferBatchList: state => state.ProductionOnTransferBatch.list,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },
  actions: {

    async getTransferList({ state, commit }, {pageNumber = 1, pageSize, startDate, endDate, fromStoreIds = [], toStoreIds = [], showDrafts}) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: pageNumber, currentPage: state.Page.number })

      const payload = {
        pageSize,
        PageNumber: PAGE_NUMBER,
        toStoreIds,
        fromStoreIds,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        showDrafts
      }
      let results = await requestHandler({
        title: 'Get Transfer Header List',
        methodName: 'get_transfer_list_header',
        method: () => {
          return HTTP.post('Transfer/header/all', payload)
        },
        success: result => {
          commit('UPDATE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getTransfer({ commit }, Id) {
      const results = await requestHandler({
        title: 'Get Transfer',
        methodName: 'get_transfer_info',
        method: () => {
          return HTTP.get(`Transfer`, {
            params: { Id }
          })
        },
        success: result => {
          commit('CHANGE_HEADER_DATA', result.data.transferHeader)
          commit('SHOW_DETAIL', result.data)
          return result
        }
      })
      return results
    },

    async getTransferDocument({ commit }, Id) {
      const results = await requestHandler({
        title: 'getTransferDocument',
        methodName: 'getTransferDocument',
        method: () => {
          return HTTP.get(`Transfer`, {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async getTransferDetail({ commit }, Id) {
      let results = await requestHandler({
        title: 'Get Transfer Detail List',
        methodName: 'get_transfer_detail_list',
        method: () => {
          return HTTP.get(`Transfer/detail`, {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createTransferHeader({commit, dispatch}, data) {
      let results = await requestHandler({
        title: 'Create Transfer Header',
        methodName: 'transfer_header_create',
        method: () => {
          return HTTP.post('Transfer/header', data)
        },
        success: result => result
      })

      return results
    },

    async createTransferFromDespatch({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'createTransferFromDespatch',
        methodName: 'createTransferFromDespatch',
        method: () => {
          return HTTP.post('Transfer/header/fromdespatch', payload)
        },
        success: result => result
      })
      return results
    },

    async updateTransferHeader({commit, dispatch}, data) {
      let results = await requestHandler({
        title: 'UPDATE Transfer Header',
        methodName: 'transfer_header_update',
        method: () => {
          return HTTP.put('Transfer/header', data)
        },
        success: result => result
      })
      return results
    },

    async deleteTransfer({commit, dispatch}, Id) {
      let results = await requestHandler({
        title: 'Transfer Header Delete',
        methodName: 'transfer_header_delete',
        method: () => {
          return HTTP.delete('Transfer', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async createTransferDetail({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Create Transfer Detail',
        methodName: 'transfer_detail_create',
        method: () => {
          return HTTP.post('Transfer/detail', data)
        },
        success: result => result
      })

      return results
    },

    async updateTransferDetail({commit}, data) {
      let results = await requestHandler({
        title: 'UPDATE Transfer Detail',
        methodName: 'transfer_header_detail',
        method: () => {
          return HTTP.put('Transfer/detail', data)
        },
        success: result => result
      })

      return results
    },

    async deleteTransferDetail({commit, dispatch}, Id) {
      let results = await requestHandler({
        title: 'Transfer Detail Delete',
        methodName: 'transfer_detail_delete',
        method: () => {
          return HTTP.delete('Transfer/detail', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async makeTransferFinalize({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'Transfer Finalize',
        methodName: 'transfer_header_delete',
        method: () => {
          return HTTP.put('Transfer/finalize', { Id: payload.id })
        },
        success: result => {
          dispatch('getTransferList', { pageNumber: 1, startDate: payload.startDate, endDate: payload.endDate })
          return result
        }
      })

      return results
    },

    async getStockInfo({ commit }, data) {
      const results = await requestHandler({
        title: 'Stock Info',
        methodName: 'transfer_stock_info',
        method: () => {
          return HTTP.get('StockOnHand/stockiteminfo', {
            params: { ...data }
          })
        },
        success: result => result
      })

      return results
    },

    async checkInventoryForStore({ commit }, params) {
      const results = await requestHandler({
        title: 'Store Inventory Info',
        methodName: 'check_inventory_info',
        method: () => {
          return HTTP.post('Inventory/header/check', params)
        },
        success: result => result
      })

      return results
    },

    async rejectTransferHeader({commit}, payload) {
      let results = await requestHandler({
        title: 'rejectTransferHeader',
        methodName: 'rejectTransferHeader',
        method: () => {
          return HTTP.put('Transfer/reject', payload)
        },
        success: result => result
      })
      return results
    },

    async sendTransferToApprove({ commit }, payload) {
      const results = await requestHandler({
        title: 'sendTransferToApprove',
        methodName: 'sendTransferToApprove',
        method: () => {
          return HTTP.put('Transfer/header/sendtoapprove', payload)
        },
        success: result => result
      })
      return results
    },

    async checkTranferTransit({commit}, params) {
      let results = await requestHandler({
        title: 'checkTranferTransit',
        methodName: 'checkTranferTransit',
        method: () => {
          return HTTP.get('Transfer/check/transit', {
            params: { ...params }
          })
        },
        success: result => result
      })
      return results
    },
    async showHistory({ commit }, payload) {
      const results = await requestHandler({
        title: 'showHistory',
        methodName: 'showHistory',
        method: () => {
          return HTTP.post('Approval/history', payload)
        },
        success: result => result
      })
      return results
    },
    async getTransferBatchData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getTransferBatchData',
        methodName: 'getTransferBatchData',
        method: () => {
          return HTTP.post('Transfer/detail/batch', payload)
        },
        success: result => {
          commit('SET_BATCH_LIST', result.data)
          commit('SET_PRODUCTION_ON_TRANSFER_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getTransferBatchDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'getTransferBatchDetail',
        methodName: 'getTransferBatchDetail',
        method: () => {
          return HTTP.post('Batch/transfer/all', payload)
        },
        success: result => result
      })
      return results
    },

    async updateBatchQuantity({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'updateBatchQuantity',
        methodName: 'updateBatchQuantity',
        method: () => {
          return HTTP.put('Batch/transfer/quantity', payload)
        },
        success: result => result
      })
      return results
    },
    async validateBatchDetails({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'validateBatchDetails',
        methodName: 'validateBatchDetails',
        method: () => {
          return HTTP.post('Transfer/detail/batch/validate', payload)
        },
        success: result => result
      })
      return results
    },

    async getTransferBatchQuantities({ commit }, payload) {
      const results = await requestHandler({
        title: 'getTransferBatchQuantities',
        methodName: 'getTransferBatchQuantities',
        method: () => {
          return HTTP.post('Transfer/detail/batch/allocate', payload)
        },
        success: result => result
      })
      return results
    },

    async getProductionOnTransferBatchDetailData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getProductionOnTransferBatchData',
        methodName: 'getProductionOnTransferBatchData',
        method: () => {
          return HTTP.post('Batch/productionontransfer/all', payload)
        },
        success: result => result
      })
      return results
    },

    async createProductionOnTransferBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createProductionOnTransferBatch',
        methodName: 'createProductionOnTransferBatch',
        method: () => {
          return HTTP.post('Batch/productionontransfer', payload)
        },
        success: result => result
      })
      return results
    },
    async validateProductionOnTransferBatchDetails({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'validateProductionOnTransferBatchDetails',
        methodName: 'validateProductionOnTransferBatchDetails',
        method: () => {
          return HTTP.post('Transfer/detail/batch/productionontransfer/validate', payload)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    RESET(state) {
      state.Transfer = new Transfers()
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
    },

    UPDATE_LIST(state, data) {
      state.Transfer.addItems(data.transferHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    //  DETAIL STATUS MUTATION
    HIDE_DETAIL(state, transferHeader) {
      transferHeader.showDetail = false
    },

    UPDATE_TRANSFER_ITEM(state, item) {
      state.Transfer.updateItem(item)
    },

    HIDE_OTHERS_DETAILS(state, transferHeader) {
      const others = state.Transfer.list.filter(transfer => transfer.id !== transferHeader.id)
      others.forEach(otherTransfer => {
        otherTransfer.showDetail = false
      })
    },

    SHOW_DETAIL(state, data) {
      let transfer = state.Transfer.findItem(data.transferHeader.id)
      if (transfer) {
        transfer.addDetails(data.transferDetailList)
        transfer.showDetail = true
      }
    },

    CHANGE_HEADER_DATA(state, header) {
      state.Transfer.updateItem(header)
    },

    SET_BATCH_LIST(state, data) {
      state.Batch.addItems(data.transferDetailList)
    },
    SHOW_BATCH_DETAIL(state, data) {
      let batchDetail = state.Batch.findItem(data.id)
      if (batchDetail) {
        batchDetail.addDetails(data.batchList)
        batchDetail.showDetail = true
      }
    },
    HIDE_BATCH_DETAIL(state, batch) {
      batch.showDetail = false
    },
    SET_EXPIRATION_DATE_CONTROL(state, data) {
      data.transfer.expirationDateControlIsEnabled = data.expirationDateControl
    },

    SET_PRODUCTION_ON_TRANSFER_BATCH_LIST(state, data) {
      state.ProductionOnTransferBatch.addItems(data.transferDetailList)
    },
    SHOW_PRODUCTION_ON_TRANSFER_BATCH_DETAIL(state, data) {
      let list
      if (data.detailList) list = data.detailList
      else list = data.batch.batchList
      let batchList = state.ProductionOnTransferBatch.findItem(data.id)
      batchList.showDetail = true
      batchList.addDetails(list)
    },
    HIDE_PRODUCTION_ON_TRANSFER_BATCH_DETAIL(state, batchList) {
      batchList.showDetail = false
    },
  }
}
